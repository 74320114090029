<template>
    <button type="button" class="btn btn-outline-danger btn-ladda btn-ladda-progress ladda-button" data-style="fade" @click="(event) => handleClick(event)">
      <i class="icon-cog3 mr-2"></i>
      <span class="ladda-label">Save</span>
      <span class="ladda-spinner"></span>
      <div class="ladda-progress" style="width: 177px;"></div>
    </button>

</template>

<script>
export default {
  name: 'SaveButton',
  methods: {
    handleClick(event) {
      this.$emit('handle_save_action', event);
    }
  }
}
</script>

<style scoped>

</style>
